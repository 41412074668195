import { Themes } from "@engaging-tech/components"
import { ClientRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { PostHogProvider } from "posthog-js/react"
import React from "react"
import { hydrate } from "react-dom"
import { useSSR } from "react-i18next"
import { Provider as StoreProvider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider } from "styled-components"

import App from "./App"
import PostHogPageviewTracker from "./PostHogPageviewTracker"
import "./locales/i18n"
import initilializeRedux from "./store"

const { store, persistor } = initilializeRedux()

const BaseApp = () => {
  const config = getConfig()
  useSSR(window.initialI18nStore, window.initialLanguage)
  const options = {
    api_host: config.posthog.api_host,
    ui_host: config.posthog.ui_host
  }
  return (
    <PostHogProvider apiKey={config.posthog.key} options={options}>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ClientRouter>
            <ThemeProvider theme={Themes.workL}>
              <App />
            </ThemeProvider>
          </ClientRouter>
        </PersistGate>
      </StoreProvider>
    </PostHogProvider>
  )
}
hydrate(<BaseApp />, document.getElementById("root"))

if (module.hot) {
  module.hot.accept()
}
