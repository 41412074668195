/* eslint-disable react/no-unescaped-entities */

/* eslint-disable no-param-reassign */
import { Flex, Icon, Spinner, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { useDebounce } from "use-debounce"

import DemographicFilter from "../../../../components/shared/demographicFilter"
import PrimaryButton from "../../../ui/components/PrimaryButton"
import flattenFilters from "../../../utils/flattenFilters"
import DisplayTable from "../../components/DisplayTable"
import Filters from "../../components/filters"
import { config, headings, mobileConfig, mobileHeadings } from "../../lib/companyTableConfig"
import { applyFilters, loadCompanies, loadMoreCompanies } from "../../store/rankings.actions"
import {
  getCompanies,
  getCompaniesIsLoading,
  getCompaniesNextToken,
  getMoreCompaniesIsLoading
} from "../../store/rankings.selectors"
import { LoadMoreWrapper, Subtitle, SubtitleUnderline } from "./happiestCompanies.styled"

const types = {
  country: "country",
  industry: "industry",
  category: "category",
  year: "year"
}

const updateField = (appliedFields, filter, type) => {
  const fieldIndex = appliedFields.findIndex(field => field.type === type)
  const typeObj = { type, ...filter }
  if (fieldIndex === -1) {
    appliedFields.push(typeObj)
  } else {
    appliedFields[fieldIndex] = typeObj
  }
}

const generateSelectedFields = (
  selectedFields,
  countryFilter,
  industryFilter,
  categoryFilter,
  yearFilter
) => {
  let appliedFields = [...selectedFields.current]

  if (countryFilter) updateField(appliedFields, countryFilter, types.country)
  if (industryFilter) updateField(appliedFields, industryFilter, types.industry)
  if (categoryFilter) updateField(appliedFields, categoryFilter, types.category)
  if (yearFilter) updateField(appliedFields, yearFilter, types.year)

  if (countryFilter === undefined) appliedFields = appliedFields.filter(field => field.type !== types.country)
  if (industryFilter === undefined)
    appliedFields = appliedFields.filter(field => field.type !== types.industry)
  if (categoryFilter === undefined)
    appliedFields = appliedFields.filter(field => field.type !== types.category)
  if (yearFilter === undefined) appliedFields = appliedFields.filter(field => field.type !== types.year)
  selectedFields.current = [...appliedFields]
}

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`

const StyledFlex = styled(Flex)`
  width: fit-content;
  border: 1px solid gray;
  padding: 4px 8px;
  border-radius: 8px;
`

export default function HappiestCompaniesContent() {
  const [searchTerm, setSearchTerm] = useState("")
  const [searchTermValue] = useDebounce(searchTerm, 500)
  const [industryFilter, setIndustryFilter] = useState(undefined)
  const [countryFilter, setCountryFilter] = useState(undefined)
  const [categoryFilter, setCategoryFilter] = useState(undefined)
  const [yearFilter, setYearFilter] = useState(undefined)

  const [demographicFilters, setDemographicFilters] = useState({})
  const [demographicsFilterIsOpen, setDemographicsFilterIsOpen] = useState(false)

  const selectedFields = useRef([])
  const paths = usePaths()

  const companiesList = useSelector(getCompanies)
  const isLoading = useSelector(getCompaniesIsLoading)
  const isLoadingMore = useSelector(getMoreCompaniesIsLoading)
  const hasNextToken = useSelector(getCompaniesNextToken)

  const dispatch = useDispatch()

  useEffect(() => {
    onFilterChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermValue, industryFilter, countryFilter, categoryFilter, demographicFilters, yearFilter])

  const onRemoveFilterItem = filterValue => {
    const newFilters = {}

    Object.keys(demographicFilters).forEach(item => {
      if (demographicFilters[item] && demographicFilters[item]?.length) {
        newFilters[item] = demographicFilters[item].filter(i => i.value !== filterValue)
      } else if (demographicFilters[item] && demographicFilters[item].value === filterValue) {
        demographicFilters[item] = undefined
      } else {
        newFilters[item] = demographicFilters[item]
      }
    })

    setDemographicFilters(newFilters)
  }

  const isDemographicFiltersSelected =
    Object.values(demographicFilters)?.length && flattenFilters(Object.values(demographicFilters))?.length

  const onFilterChange = useCallback(() => {
    dispatch(applyFilters({ yearFilter, countryFilter }))
    dispatch(
      loadCompanies({
        searchTerm: searchTermValue,
        industryFilter,
        countryFilter,
        categoryFilter,
        yearFilter,
        filters: demographicFilters
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermValue, industryFilter, countryFilter, categoryFilter, demographicFilters, yearFilter])

  const onLoadMore = () => {
    dispatch(
      loadMoreCompanies({
        searchTerm,
        industryFilter,
        countryFilter,
        categoryFilter,
        yearFilter,
        filters: demographicFilters
      })
    )
  }

  generateSelectedFields(selectedFields, countryFilter, industryFilter, categoryFilter, yearFilter)

  const handleClick = type => {
    if (type === types.country) setCountryFilter(undefined)
    if (type === types.industry) setIndustryFilter(undefined)
    if (type === types.category) setCategoryFilter(undefined)
    if (type === types.year) setYearFilter(undefined)
  }

  return (
    <>
      <Flex my={2} px={[3, 0]} justifyContent="flex-start" flexDirection="column" alignItems="flex-start">
        <Filters
          setDemographicsFilterIsOpen={setDemographicsFilterIsOpen}
          isDemographicFiltersSelected={isDemographicFiltersSelected}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          countryFilter={countryFilter}
          setCountryFilter={setCountryFilter}
          industryFilter={industryFilter}
          setIndustryFilter={setIndustryFilter}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          demographicsFilter={demographicFilters}
          onRemoveFilterItem={onRemoveFilterItem}
          yearFilter={yearFilter}
          setYearFilter={setYearFilter}
        />

        <Flex width={1 / 1} mt={2}>
          {selectedFields.current?.map(({ name, type, id }) => (
            <StyledFlex width="fit-content" alignItems="center" key={id}>
              <Text mr={2}>{name}</Text>
              {id !== "happinessScores" && <StyledIcon name="clear" onClick={() => handleClick(type)} />}
            </StyledFlex>
          ))}
        </Flex>

        <Subtitle mt={15}>
          Click on a organisation's name to view their current vacancies on the
          <Link to={paths.findAJob.index}>
            <SubtitleUnderline ml={1} mr={1}>
              Jobs Board.
            </SubtitleUnderline>
          </Link>
        </Subtitle>
      </Flex>
      <Flex py={10} flexDirection="column" justifyContent="center">
        <DisplayTable
          config={config}
          rows={companiesList?.length ? companiesList : []}
          headings={headings}
          mobileConfig={mobileConfig}
          mobileHeadings={mobileHeadings}
          isLoading={isLoading}
          isDemographicFiltersSelected={isDemographicFiltersSelected}
          type="companies"
          width={100}
        />

        <LoadMoreWrapper>
          {isLoadingMore ? (
            <Spinner mt={4} mb={4} color="primary.0" width={1 / 1} />
          ) : (
            <PrimaryButton
              onClick={onLoadMore}
              width={1 / 1}
              maxWidth={300}
              disabled={!hasNextToken || isLoading || isLoadingMore}
              color="light.0"
              text={!hasNextToken ? "Loaded all companies" : "Load more"}
            />
          )}
        </LoadMoreWrapper>
      </Flex>

      {demographicsFilterIsOpen ? (
        <DemographicFilter
          filters={demographicFilters}
          toggle={setDemographicsFilterIsOpen}
          onApplyFilters={filters => {
            setDemographicsFilterIsOpen(false)
            setDemographicFilters(filters)
          }}
        />
      ) : null}
    </>
  )
}
