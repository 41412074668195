import { TextField } from "@engaging-tech/components"
import React from "react"

const TextSearchInput = ({ placeholder = "", value, onChangeValue }) => {
  const handleInputChange = ({ target: { value: inputValue } }) => onChangeValue(inputValue)

  return (
    <TextField
      id="searchTextField"
      noLabel
      fontSize={14}
      height={36}
      width={[1 / 1, 265]}
      borderRadius={100}
      noHighlight
      onChange={handleInputChange}
      value={value}
      placeholder={placeholder}
      my={[2, 0]}
      mx={2}
    />
  )
}

export default TextSearchInput
