import { Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import DemographicChips from "../../../../components/shared/demographicFilter/demographicChips"
import DemographicFilterButton from "../../../../components/shared/demographicFilter/demographicFilterButton"
import { loadFilters } from "../../../../components/shared/demographicFilter/store/demographicFilter.actions"
import { getDemographicFiltersList } from "../../../../components/shared/demographicFilter/store/demographicFilter.selectors"
import { GET_WHW_FILTERS } from "../../../../graphql/queries/filters"
import useAppSyncQuery from "../../../../hooks/useAppSyncQuery"
import { Title } from "./filters.styled"
import SelectInput from "./selectInput"
import TextSearchInput from "./textSearchInput"

export default function Filters({
  setDemographicsFilterIsOpen,
  isDemographicFiltersSelected,
  searchTerm,
  setSearchTerm,
  countryFilter,
  setCountryFilter,
  industryFilter,
  setIndustryFilter,
  categoryFilter,
  setCategoryFilter,
  demographicsFilter,
  onRemoveFilterItem,
  yearFilter,
  setYearFilter
}) {
  const filters = useAppSyncQuery(GET_WHW_FILTERS, {
    variables: {},
    isPublic: true
  })

  const demographicFiltersList = useSelector(getDemographicFiltersList)
  const dispatch = useDispatch()

  useEffect(() => {
    // Load demographic filters
    dispatch(loadFilters())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!demographicFiltersList])

  return (
    <>
      <Flex flexDirection={["column", "row"]} justifyContent="space-between" width={1}>
        <Title>Organisation Ranking</Title>
        <Flex flexDirection={["column", "row"]} justifyContent="space-between">
          <TextSearchInput
            placeholder="Search by organisation name"
            value={searchTerm}
            onChangeValue={setSearchTerm}
          />
          {demographicFiltersList ? (
            <DemographicFilterButton
              isSelected={isDemographicFiltersSelected}
              onOpen={() => {
                setDemographicsFilterIsOpen(true)
              }}
            />
          ) : null}
        </Flex>
      </Flex>
      <Flex py={2} flexDirection={["column", "row"]} justifyContent="space-between" width={1 / 1}>
        <SelectInput
          options={{
            id: "filter-country",
            label: "Country",
            items: filters?.data?.getFiltersMeta?.country || []
          }}
          isLoading={filters?.isLoading}
          filterValue={countryFilter}
          onFilter={setCountryFilter}
          height={36}
        />
        <SelectInput
          options={{
            id: "filter-industry",
            label: "Industry",
            items: filters?.data?.getFiltersMeta?.industry || []
          }}
          isLoading={filters?.isLoading}
          filterValue={industryFilter}
          onFilter={setIndustryFilter}
        />

        {filters?.data?.getFiltersMeta?.year && (
          <SelectInput
            options={{
              id: "filter-year",
              label: "Year",
              items: filters?.data?.getFiltersMeta?.year || []
            }}
            isLoading={filters?.isLoading}
            filterValue={yearFilter}
            onFilter={setYearFilter}
          />
        )}

        <SelectInput
          awards
          options={{
            id: "filter-category",
            label: "Awards",
            items: filters?.data?.getFiltersMeta?.awards || []
          }}
          isLoading={filters?.isLoading}
          filterValue={categoryFilter}
          onFilter={setCategoryFilter}
        />
      </Flex>

      <DemographicChips demographicsFilter={demographicsFilter} onRemoveFilterItem={onRemoveFilterItem} />
    </>
  )
}
