import { Flex, TextField } from "@engaging-tech/components"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import debounce from "../../../../helpers/debounce"
import OutlineButton from "../OutlineButton"
import SearchButton from "./SearchButton"

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.light[0]};
`

const Wrapper = styled(Flex)`
  flex-shrink: 0;
`

const handleSubmit = (onSearch, passedInSearchTerm, event, postType, category) => {
  if (event) {
    event.preventDefault()
  }
  onSearch(passedInSearchTerm, postType, category)
}
const debouncedHandleSubmit = debounce(handleSubmit, 700)

const SearchBar = ({
  hasSearched = null,
  onSearch,
  automaticSearch = false,
  onClear,
  onChangeCallback,
  initialSearchTerm = "",
  placeholder = "Search",
  validator,
  postType,
  category,
  width = "auto",
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  useEffect(() => {
    if (onClear) {
      onClear(postType, category)
    }
  }, [])

  useEffect(() => {
    setSearchTerm(initialSearchTerm)
  }, [initialSearchTerm])

  const handleChange = e => {
    setSearchTerm(e.target.value)
    if (onChangeCallback) {
      onChangeCallback(e.target.value)
    }
    if (automaticSearch) {
      debouncedHandleSubmit(onSearch, e.target.value)
    }
  }

  const handleReset = () => {
    setSearchTerm("")
    if (onClear) {
      onClear(postType, category)
    }
  }

  const isValid = validator ? validator(searchTerm) : true

  return (
    <Wrapper alignItems="center" justifyContent="flex-start" width={width} {...props}>
      <Flex
        as="form"
        onSubmit={e => {
          handleSubmit(onSearch, searchTerm, e, postType, category)
        }}
        alignItems="center"
        justifyContent="flex-start"
        width={width}
      >
        <StyledTextField
          id="searchTextField"
          noLabel
          width={width}
          height={40}
          borderRadius={20}
          border="1px solid rgb(1, 62,76)"
          bg="light.0"
          iconStyle={{
            color: searchTerm ? "primary.0" : "dark.3",
            width: "20px"
          }}
          fontSize={16}
          iconName="search"
          noHighlight
          onChange={handleChange}
          value={searchTerm}
          placeholder={placeholder}
          {...props}
        />
        {!automaticSearch && (
          <SearchButton
            icon="search"
            type="submit"
            size={40}
            onClick={() => {
              if (onSearch) onSearch(searchTerm, postType, category)
            }}
            disabled={!isValid}
          />
        )}
      </Flex>
      {hasSearched && !automaticSearch && (
        <OutlineButton ml={3} text="Clear Search" onClick={handleReset} width="auto" height={40} />
      )}
    </Wrapper>
  )
}

export default SearchBar
