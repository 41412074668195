import { Spinner } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"

import {
  ArrowDownIcon,
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  FilterDropdownIcon
} from "./selectInputs.styled"

const SelectInput = ({ type = "filter", options, filterValue, onFilter, awards, isLoading }) => {
  const dropdownRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const handleOptionClick = option => {
    if (option.id === filterValue?.id) {
      onFilter(undefined)
    } else {
      onFilter(option)
      toggleDropdown()
    }
  }

  const filterButtonLabel = isLoading && filterValue?.name ? `${filterValue.name}` : filterValue?.name
  const filterOptionLabel = isLoading ? `${options?.label}` : options?.label
  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton isOpen={isOpen} onClick={toggleDropdown} selected={filterValue} awards={awards}>
        {type === "sort" && filterValue?.name ? filterButtonLabel : filterOptionLabel}
        {type === "filter" ? (
          isLoading ? (
            <Spinner color="primary.0" size={14} mr={2} />
          ) : (
            <ArrowDownIcon isopen={isOpen ? "true" : undefined} />
          )
        ) : isLoading ? (
          <Spinner color="primary.0" size={14} mr={2} />
        ) : (
          <FilterDropdownIcon />
        )}
      </DropdownButton>
      {!isLoading && isOpen && (
        <DropdownList>
          {options.items.map((item, index) => (
            <DropdownItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              selected={filterValue?.id === item.id}
              onClick={() => handleOptionClick(item)}
            >
              {item.name}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}

export default SelectInput
