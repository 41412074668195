import { Flex, Text } from "@engaging-tech/components"
import React from "react"

import AutoCarousel from "../../../components/auto-carousal"
import { MULTI_CARD_CAROUSEL_TYPE } from "../../../components/auto-carousal/autoCarousel.styled"
import { GET_WHW_SPONSORS_BY_COUNTRY } from "../../../graphql/queries/sponsors.queries"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"

const SponsorsCarouselRow = ({ label = "Global Partners", slides = [], options = {} }) => {
  return (
    <Flex flexDirection="column" width={1 / 1}>
      <Flex justifyContent="center" alignItems="center">
        <Text textAlign="center" fontSize={14} fontWeight={400} mb={3} mt={3} width={1 / 1} lineHeight="20px">
          {label}
        </Text>
      </Flex>
      <AutoCarousel type={MULTI_CARD_CAROUSEL_TYPE} slides={slides} options={options} />
    </Flex>
  )
}

const SponsorsCarousel = () => {
  const allSponsors = useAppSyncQuery(GET_WHW_SPONSORS_BY_COUNTRY, {
    variables: { countryId: "" },
    isPublic: true
  })
  if (allSponsors?.isLoading) return null

  return (
    <Flex flexDirection="column" width={[1 / 1]}>
      {allSponsors?.data?.getAwardSponsors?.globalPartners?.data?.length ? (
        <SponsorsCarouselRow slides={allSponsors?.data?.getAwardSponsors?.globalPartners?.data || []} />
      ) : null}
      {allSponsors?.data?.getAwardSponsors?.partners?.data?.length ? (
        <SponsorsCarouselRow
          label="Partners"
          slides={allSponsors?.data?.getAwardSponsors?.partners?.data || []}
        />
      ) : null}
      {allSponsors?.data?.getAwardSponsors?.sponsors?.data?.length ? (
        <SponsorsCarouselRow
          label="Sponsors"
          slides={allSponsors?.data?.getAwardSponsors?.sponsors?.data || []}
        />
      ) : null}
      {allSponsors?.data?.getAwardSponsors?.mediaSponsors?.data?.length ? (
        <SponsorsCarouselRow
          label="Media Sponsors"
          slides={allSponsors?.data?.getAwardSponsors?.mediaSponsors?.data || []}
        />
      ) : null}
    </Flex>
  )
}

export default SponsorsCarousel
