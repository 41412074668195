import { Flex } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { GET_WHW_SPONSORS_BY_COUNTRY } from "../../../graphql/queries/sponsors.queries"
import { mergeSponsors } from "../../../helpers/sponsors"
import useAppSyncQuery from "../../../hooks/useAppSyncQuery"
import { getActiveFilters } from "../store/rankings.selectors"
import WHWSponsorsCarousel from "./WHWSponsors"

const SideCards = () => {
  const activeFilters = useSelector(getActiveFilters)
  const activeCountryFilter = activeFilters?.activeCountry?.id || null

  const allSponsors = useAppSyncQuery(GET_WHW_SPONSORS_BY_COUNTRY, {
    variables: { countryId: activeCountryFilter },
    isPublic: true,
    enabledOnMount: false
  })

  useEffect(() => {
    allSponsors.getData({ countryId: activeCountryFilter })
  }, [activeCountryFilter])

  const allPartnersLabel = activeCountryFilter ? `${activeCountryFilter} Partners` : "All Partners"

  const mergedSponsors = allSponsors?.data?.getAwardSponsors
    ? mergeSponsors(allSponsors?.data?.getAwardSponsors)
    : []

  return (
    <Flex flexDirection="column" width={[1 / 1, 1 / 1, 1 / 3]} mx={2} my={[5, 5, 0]}>
      <WHWSponsorsCarousel
        isLoading={allSponsors?.isLoading}
        slides={allSponsors?.data?.getAwardSponsors?.globalPartners?.data || []}
      />
      <WHWSponsorsCarousel
        isLoading={allSponsors?.isLoading}
        label={allPartnersLabel}
        slides={mergedSponsors}
      />
    </Flex>
  )
}

export default SideCards
