import { Box, Card, Flex, MobileOnly, TabletUp } from "@engaging-tech/components"
import React from "react"

import AwardRankingBanner from "../../components/AwardRankingBanner"
import SideCards from "../../components/SideCards"
import HappiestCompaniesContent from "./happiestCompaniesContent"

export default function HappiestCompanies() {
  return (
    <Box
      width={1 / 1}
      mx="auto"
      style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
    >
      <AwardRankingBanner />
      <Flex flexDirection={["column", "column", "row"]} style={{ padding: "20px", maxWidth: "1280px" }}>
        <MobileOnly>
          <HappiestCompaniesContent />
        </MobileOnly>
        <TabletUp>
          <Card width={1} px={[0, 3]} py={4}>
            <HappiestCompaniesContent />
          </Card>
        </TabletUp>
        <SideCards />
      </Flex>
    </Box>
  )
}
