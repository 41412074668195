import { Button } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import FilterIconSVG from "../../../assets/icons/filter-icon"

const ButtonTitleWrapper = styled.span`
  padding-left: 5px;
  color: ${({ isSelected }) => (isSelected ? "#00e96e" : " #FFFFFF")};
`
export default function DemographicFilterButton({ isSelected, onOpen }) {
  return (
    <Button mx={2} my={[2, 0]} variant="primary.0" width="100%" onClick={onOpen} height={36}>
      <FilterIconSVG width="20px" height="20px" color={isSelected ? "#00e96e" : "#FFFFFF"} />
      <ButtonTitleWrapper isSelected={isSelected}>Happiest Workplaces For You</ButtonTitleWrapper>
    </Button>
  )
}
