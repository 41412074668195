import { Card } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import AutoPlay from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"
import React, { useEffect } from "react"

import {
  EmblaContainer,
  EmblaFlex,
  EmblaSlide,
  EmblaSlideCard,
  EmblaSlideImg,
  EmblaSlideImgContainer,
  EmblaViewport,
  SINGLE_CARD_CAROUSEL_TYPE
} from "./autoCarousel.styled"

const Carousel = props => {
  const { slides, options, type } = props
  const config = getConfig()

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      ...options,
      duration: 120,
      slidesToScroll: "auto",
      containScroll: "trimSnaps",
      dragFree: true
    },
    [AutoPlay({ stopOnInteraction: false, jump: false })]
  )

  const CardComponent = type === SINGLE_CARD_CAROUSEL_TYPE ? EmblaSlide : EmblaSlideCard
  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    return () => {
      const destroyAutoplay = autoScroll.destroy
      destroyAutoplay()
    }
  }, [emblaApi])

  return (
    <EmblaFlex type={type}>
      <EmblaViewport ref={emblaRef}>
        <EmblaContainer type={type} totalSlides={slides?.length}>
          {slides.map((slide, index) => {
            const imageSrc = slide?.imageUrl ? `${config.publicCDNPath}${slide?.imageUrl}` : null
            return (
              <CardComponent key={index}>
                <EmblaSlideImgContainer type={type}>
                  <EmblaSlideImg type={type} loading="lazy" src={imageSrc} alt={slide?.description} />
                </EmblaSlideImgContainer>
              </CardComponent>
            )
          })}
        </EmblaContainer>
      </EmblaViewport>
    </EmblaFlex>
  )
}
const AutoCarousel = props => {
  const { type } = props

  return type === SINGLE_CARD_CAROUSEL_TYPE ? (
    <Card p={3} width={[1 / 1, 1 / 1, "240px"]}>
      <Carousel {...props} />
    </Card>
  ) : (
    <Carousel {...props} />
  )
}

export default AutoCarousel
