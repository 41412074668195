import Pages from "./pages"

const routes = [
  {
    path: "/",
    description: "Home",
    component: Pages.developCareer.index
  },
  {
    path: "/down-for-maintenance",
    description: "Down For Maintenance",
    component: Pages.DownForMaintenance
  },
  {
    path: "/translation",
    description: "Language translation",
    component: Pages.translation
  },
  {
    path: "/global-hub",
    description: "Global Hub",
    icon: "language",
    component: Pages.globalHub.index,
    linkProps: {
      path: "/global-hub",
      external: true
    },
    children: [
      {
        path: "/global-hub/publish",
        description: "New Post",
        private: true,
        component: Pages.globalHub.publish
      },
      {
        path: "/global-hub/:id",
        description: "Selected Post",
        private: true,
        component: Pages.globalHub.selected
      }
    ]
  },
  {
    path: "/find-work",
    description: "Find a Job",
    icon: "work",
    component: Pages.jobs.index,
    children: [
      {
        path: "/find-work/jobs",
        highlightPaths: ["/find-work"],
        description: "Job Finder",
        private: true,
        component: Pages.jobs.index,
        children: [
          {
            path: "/find-work/jobs/:id",
            description: "Job Details",
            private: true,
            component: Pages.jobs.details
          }
        ]
      }
    ]
  },
  {
    path: "/develop-career",
    description: "Career Developer",
    icon: "assessment",
    highlightPaths: ["/"],
    component: Pages.developCareer.index,
    children: [
      {
        path: "/develop-career/your-profile",
        highlightPaths: ["/develop-career"],
        description: "Your Profile",
        private: true,
        component: Pages.developCareer.index
      },
      {
        path: "/develop-career/your-surveys",
        description: "Your Surveys",
        private: true,
        component: Pages.developCareer.yourSurveys
      },
      {
        path: "/develop-career/your-tests",
        description: "Your Tests",
        private: true,
        component: Pages.developCareer.yourTests
      },
      {
        path: "/develop-career/your-surveys/results/:id",
        private: true,
        description: "Survey Results Page",
        component: Pages.developCareer.resultsPage
      },
      {
        path: "/develop-career/mentor-matcher",
        description: "Mentor Matcher",
        private: true,
        component: Pages.developCareer.mentorMatcher
      },
      {
        path: "/develop-career/cv-library",
        description: "CV Library",
        private: true,
        component: Pages.developCareer.cvLibrary,
        children: [
          {
            path: "/develop-career/cv-library/new",
            description: "Create CV",
            private: true,
            component: Pages.developCareer.createCV
          },
          {
            path: "/develop-career/cv-library/cv/:id",
            description: "Created CV Page",
            private: true,
            component: Pages.developCareer.selectedCV
          },
          {
            path: "/develop-career/cv-library/:id/edit",
            description: "Edit CV",
            private: true,
            component: Pages.developCareer.createCV
          }
        ]
      },
      {
        path: "/develop-career/job-matcher",
        description: "Job Matcher",
        private: true,
        component: Pages.developCareer.jobMatcher.index,
        children: [
          {
            path: "/develop-career/job-matcher/criteria",
            description: "Set Criteria Form",
            private: true,
            component: Pages.developCareer.jobMatcher.setCriteria
          }
        ]
      }
    ]
  },
  {
    path: "/business-profile",
    description: "Company Business Profile",

    children: [
      {
        path: "/business-profile/:profileId",
        description: "Company Business Page",
        component: Pages.BusinessProfile
      }
    ]
  },
  {
    path: "/connect",
    description: "Connect",
    private: true,
    component: Pages.connectUsers
  },
  {
    path: "/happiness-survey",
    description: "Happiness Survey",
    icon: "sunny",
    component: Pages.happinessSurvey.welcome,
    children: [
      {
        path: "/happiness-survey/rank-company",
        description: "Rank Your Company",
        component: Pages.happinessSurvey.rankCompany
      },
      {
        path: "/happiness-survey/welcome",
        description: "Language",
        component: Pages.happinessSurvey.welcome
      },
      {
        path: "/happiness-survey/start",
        description: "Questions",
        component: Pages.happinessSurvey.questions
      },
      {
        path: "/happiness-survey/submit",
        description: "Submit",
        component: Pages.happinessSurvey.submit
      },
      {
        path: "/happiness-survey/results/:id",
        description: "Results",
        component: Pages.happinessSurvey.results
      }
    ]
  },
  {
    path: "/savanta-survey",
    description: "Savanta Survey",
    icon: "sunny",
    component: Pages.happinessSurvey.welcomeSavanta
  },
  {
    path: "/organisationSurvey/:id",
    description: "Survey",
    icon: "accessibility",
    component: Pages.organisationSurvey.welcome,
    children: [
      {
        path: "/organisationSurvey/:id/start",
        description: "Questions",
        component: Pages.organisationSurvey.start
      },
      {
        path: "/organisationSurvey/:id/submit",
        description: "Submit",
        component: Pages.organisationSurvey.submit
      },
      {
        path: "/organisationSurvey/results/:id",
        description: "Results",
        component: Pages.organisationSurvey.results
      }
    ]
  },
  {
    path: "/premium",
    description: "Get Premium",
    component: Pages.account.accountComparison,
    children: [
      {
        path: "/premium/subscribe",
        description: "Subscribe to Premium",
        component: Pages.premium.subscribe
      },
      {
        path: "/premium/features",
        description: "Premium Features",
        component: Pages.premium.features
      },
      {
        path: "/premium/success",
        description: "Premium Subscription Success",
        component: Pages.premium.success
      }
    ]
  },
  {
    path: "/business-library",
    description: "Resource Centre",
    icon: "local_grocery_store",
    component: Pages.businessLibrary.index,
    children: [
      {
        path: "/business-library/discover",
        highlightPaths: ["/business-library"],
        description: "Discover",
        component: Pages.businessLibrary.discover
      },
      {
        path: "/business-library/browse",
        description: "Browse",
        component: Pages.businessLibrary.browse.podcasts,
        children: [
          {
            path: "/business-library/browse/books",
            description: "Books",
            component: Pages.businessLibrary.browse.books,
            children: [
              {
                path: "/business-library/browse/books/:id",
                description: "Selected Book",
                component: Pages.businessLibrary.selected.book
              }
            ]
          },
          {
            path: "/business-library/browse/podcasts",
            description: "Podcasts",
            component: Pages.businessLibrary.browse.podcasts,
            children: [
              {
                path: "/business-library/browse/podcasts/:id",
                description: "Selected Podcast",
                component: Pages.businessLibrary.selected.podcast
              }
            ]
          },
          {
            path: "/business-library/browse/articles",
            description: "Articles",
            component: Pages.businessLibrary.browse.articles,
            children: [
              {
                path: "/business-library/browse/articles/:id",
                description: "Selected Article",
                component: Pages.businessLibrary.selected.article
              }
            ]
          },
          {
            path: "/business-library/browse/lectures",
            description: "Lectures",
            component: Pages.businessLibrary.browse.lectures,
            children: [
              {
                path: "/business-library/browse/lectures/:id",
                description: "Selected Lecture",
                component: Pages.businessLibrary.selected.lecture
              }
            ]
          },
          {
            path: "/business-library/browse/research",
            description: "Research",
            component: Pages.businessLibrary.browse.research,
            children: [
              {
                path: "/business-library/browse/research/:id",
                description: "Selected Research",
                component: Pages.businessLibrary.selected.research
              }
            ]
          }
        ]
      },
      {
        path: "/business-library/publish",
        description: "Publish",
        private: true,
        component: Pages.businessLibrary.publish.podcast,
        children: [
          {
            path: "/business-library/publish/podcast",
            description: "Podcast",
            component: Pages.businessLibrary.publish.podcast
          },
          {
            path: "/business-library/publish/article",
            description: "Article",
            component: Pages.businessLibrary.publish.article
          },
          {
            path: "/business-library/publish/lecture",
            description: "Lecture",
            component: Pages.businessLibrary.publish.lecture
          },
          {
            path: "/business-library/publish/research",
            description: "Research",
            component: Pages.businessLibrary.publish.research
          }
        ]
      },
      {
        path: "/business-library/my-library",
        description: "My Library",
        component: Pages.myLibrary.index,
        private: true,
        children: [
          {
            path: "/business-library/my-library/articles",
            description: "Bookmarked Articles",
            component: Pages.myLibrary.articles,
            children: [
              {
                path: "/business-library/my-library/articles/:id",
                description: "Bookmarked Article",
                component: Pages.myLibrary.selected
              }
            ]
          },
          {
            path: "/business-library/my-library/podcasts",
            description: "Bookmarked Podcast",
            component: Pages.myLibrary.podcasts,
            children: [
              {
                path: "/business-library/my-library/podcasts/:id",
                description: "Bookmarked Podcast",
                component: Pages.myLibrary.selected
              }
            ]
          },
          {
            path: "/business-library/my-library/lectures",
            description: "Bookmarked Lectures",
            component: Pages.myLibrary.lectures,
            children: [
              {
                path: "/business-library/my-library/lectures/:id",
                description: "Bookmarked Lecture",
                component: Pages.myLibrary.selected
              }
            ]
          },
          {
            path: "/business-library/my-library/research",
            description: "Bookmarked Research",
            component: Pages.myLibrary.research,
            children: [
              {
                path: "/business-library/my-library/research/:id",
                description: "Bookmarked Research Item",
                component: Pages.myLibrary.selected
              }
            ]
          },
          {
            path: "/business-library/my-library/ebooks",
            description: "Bookmarked Ebooks",
            component: Pages.myLibrary.ebooks,
            children: [
              {
                path: "/business-library/my-library/ebooks/:id",
                description: "Bookmarked Ebook",
                component: Pages.myLibrary.selected
              }
            ]
          },
          {
            path: "/business-library/my-library/audiobooks",
            description: "Bookmarked Audiobooks",
            component: Pages.myLibrary.audiobooks,
            children: [
              {
                path: "/business-library/my-library/audiobooks/:id",
                description: "Bookmarked Audiobook",
                component: Pages.myLibrary.selected
              }
            ]
          }
        ]
      },
      {
        path: "/business-library/basket",
        description: "Basket",
        component: Pages.businessLibrary.basket.index,
        children: [
          {
            path: "/business-library/basket/checkout",
            description: "Checkout",
            component: Pages.businessLibrary.basket.checkout
          }
        ]
      },
      {
        path: "/business-library/purchased",
        description: "Purchased",
        component: Pages.businessLibrary.purchased.index,
        children: [
          {
            path: "/business-library/purchased/:id",
            description: "Order Details",
            component: Pages.businessLibrary.purchased.selected
          }
        ]
      }
    ]
  },
  {
    path: "/worlds-happiest-workplaces",
    description: "Worlds Happiest Workplaces",
    icon: "work",
    highlightPaths: ["/worlds-happiest-workplaces", "/business-profile/:profileId"],
    component: Pages.happiestCompanies.index,
    children: [
      {
        path: "/worlds-happiest-workplaces/companies-list",
        description: "Worlds Happiest Workplaces",
        component: Pages.happiestCompanies.index
      }
    ]
  },
  {
    path: "/account",
    description: "Account",
    icon: "user-circle",
    component: Pages.account.index,
    children: [
      {
        path: "/account/comparison",
        description: "Account Comparison",
        icon: "account_circle",
        component: Pages.account.accountComparison
      },
      {
        path: "/account/sign-in",
        description: "Sign In",
        icon: "account_circle",
        component: Pages.account.signIn
      },
      {
        path: "/account/sign-up",
        description: "Sign Up",
        icon: "add_circle",
        component: Pages.account.signUp
      },
      {
        path: "/account/forgot-password",
        description: "Forgot Password",
        component: Pages.account.forgotPassword
      },
      {
        path: "/account/magic-link",
        description: "Magic Link",
        component: Pages.account.sendMagicLink
      },
      {
        path: "/account/magic-link/sent",
        description: "Magic Link Sent",
        component: Pages.account.magicLinkMessage
      },
      {
        path: "/account/magic-link/redirect",
        description: "Magic Link Redirect",
        component: Pages.account.magicLinkRedirect
      },
      {
        path: "/account/reset-password/:email/:code",
        description: "Reset Password",
        component: Pages.account.resetPassword
      },
      {
        path: "/account/confirm/:confirmToken",
        description: "Confirm Email",
        component: Pages.account.confirmEmail
      },
      {
        path: "/account/thank-you",
        description: "Thank You",
        component: Pages.account.thankYou
      }
    ]
  },
  {
    path: "/legal",
    description: "Legal",
    component: Pages.legal.terms,
    children: [
      {
        path: "/legal/cookies",
        description: "Cookies",
        component: Pages.legal.cookies
      },
      {
        path: "/legal/privacy-policy",
        description: "Privacy Policy",
        component: Pages.legal.privacy
      },
      {
        path: "/legal/terms-and-conditions",
        description: "Terms and Conditions",
        component: Pages.legal.terms
      }
    ]
  },
  {
    path: "/about",
    description: "About Us",
    component: Pages.About
  },
  {
    path: "/onboarding",
    description: "Onboarding",
    component: Pages.Onboarding
  },
  {
    path: "/surveypage/cmi",
    description: "CMI page",
    component: Pages.companies.CMI
  },
  {
    path: "/in-app-view/global-hub",
    description: "Engaging Works app global hub",
    private: true,
    component: Pages.appView.globalHub,
    children: [
      {
        path: "/in-app-view/global-hub/:id",
        description: "Individual globalhub Post",
        component: Pages.appView.globalHubPost
      }
    ]
  },
  {
    path: "/in-app-view/jobs",
    description: "Engaging Works app",
    private: true,
    component: Pages.appView.appJobs,
    children: [
      {
        path: "/in-app-view/jobs/:id",
        description: "Individual Job Post",
        component: Pages.appView.job
      }
    ]
  },
  {
    path: "/in-app-view/business-profile/:profileId",
    description: "In App View Business profile",
    private: false,
    component: Pages.appView.businessProfile
  },
  {
    path: "/in-app-view/happiness-survey",
    description: "In App View Happiness Survey",
    icon: "sunny",
    component: Pages.appView.happinessSurvey.welcome,
    children: [
      {
        path: "/in-app-view/happiness-survey/rank-company",
        description: "Rank Your Company",
        component: Pages.appView.happinessSurvey.rankCompany
      },
      {
        path: "/in-app-view/happiness-survey/welcome",
        description: "Language",
        component: Pages.appView.happinessSurvey.welcome
      },
      {
        path: "/in-app-view/happiness-survey/start",
        description: "Questions",
        component: Pages.appView.happinessSurvey.questions
      },
      {
        path: "/in-app-view/happiness-survey/submit",
        description: "Submit",
        component: Pages.appView.happinessSurvey.submit
      },
      {
        path: "/in-app-view/happiness-survey/results/:id",
        description: "Results",
        component: Pages.appView.happinessSurvey.results
      }
    ]
  },
  {
    path: "/in-app-view/organisationSurvey/:id",
    description: "In App View Organisation Survey",
    icon: "accessibility",
    component: Pages.appView.organisationSurvey.welcome,
    children: [
      {
        path: "/in-app-view/organisationSurvey/:id/start",
        description: "Start",
        component: Pages.appView.organisationSurvey.start
      },
      {
        path: "/in-app-view/organisationSurvey/:id/submit",
        description: "Submit",
        component: Pages.appView.organisationSurvey.submit
      },
      {
        path: "/in-app-view/organisationSurvey/results/:id",
        description: "Results",
        component: Pages.organisationSurvey.results
      }
    ]
  },
  {
    path: "/in-app-view/develop-career/cv-library",
    description: "In App View CV Library",
    component: Pages.appView.developCareer.cvLibrary
  },
  {
    path: "/in-app-view/develop-career/cv-library/cv/:id",
    description: "In App View Selected CV",
    component: Pages.appView.developCareer.selectedCv
  },
  {
    path: "/in-app-view/develop-career/cv-library/new",
    description: "In App View Create CV",
    component: Pages.appView.developCareer.createCV
  },
  {
    path: "/in-app-view/develop-career/cv-library/:id/edit",
    description: "In App View Edit CV",
    component: Pages.appView.developCareer.createCV
  },
  {
    path: "/saml",
    description: "Saml",
    component: Pages.account.saml
  },
  {
    path: "/messenger",
    description: "Messenger",
    component: Pages.Messenger
  }
]

export default routes
