import { createSelector } from "reselect"

const rankingState = state => state.rankingsData

export const getCompanies = createSelector(rankingState, state => state.companies)

export const getCompaniesIsLoading = createSelector(rankingState, state => state.isCompaniesLoading)

export const getMoreCompaniesIsLoading = createSelector(rankingState, state => state.isMoreCompaniesLoading)

export const getCompaniesNextToken = createSelector(rankingState, state => state.nextToken)

export const getLoadFrom = createSelector(rankingState, state => state.loadFrom)

export const getRankings = createSelector(rankingState, state => state && state.rankings)

export const getIsLoading = createSelector(rankingState, state => state && state.isLoading)

export const getIsLoadingMore = createSelector(rankingState, state => state && state.isLoadingMore)

export const getHasLoadedAll = createSelector(rankingState, state => state && state.hasLoadedAll)

export const getCompanyRankings = createSelector(getRankings, rankings => rankings && rankings.company)

const formatCompanyRows = ({
  ranking,
  company,
  industry,
  country,
  recommendation,
  happinessScores,
  profile,
  companyNameKey
}) => ({
  ranking,
  company,
  companyNameKey,
  industry: industry?.name,
  country: country?.name,
  recommendation: `${Math.round(recommendation * 100)}%`,
  happinessScore: Math.round(happinessScores?.score * 100),
  profile
})

export const getCompanyTableRows = createSelector(getCompanyRankings, rankings =>
  rankings.map(rankingData => formatCompanyRows(rankingData))
)

export const getIsLoadingSurveyCompanies = createSelector(
  rankingState,
  rankings => rankings && rankings.isLoadingSurveyCompanies
)

const companiesSorted = companies => companies?.sort((a, b) => a.name.localeCompare(b.name))

export const getSurveyCompanies = createSelector(
  rankingState,
  rankings =>
    rankings?.surveyCompanies &&
    companiesSorted(rankings?.surveyCompanies.map(company => ({ id: company.id, name: company.companyName })))
)

export const getActiveFilters = createSelector(rankingState, state => state && state?.filters)
