import { GoalWidget, LatestScore, ManageAlertsWidget, ProfileWidget } from ".."
import { mobileColumns } from "../../lib/gridSetup"
import TrainingCentreWidget from "../components/TrainingCentreWidget"
import JobMatcherWidget from "../components/jobMatcherWidget"
import AboutYourself from "../containers/AboutYourself"
import AdviceWidget from "../containers/AdviceWidget"
import CVLibraryWidget from "../containers/CVLibraryWidget"
import ContentForYouWidget from "../containers/ContentForYouWidget"
import JobFinderWidget from "../containers/JobFinderWidget"
import MentorMatcherWidget from "../containers/MentorMatcherWidget"
import SavedContentWidget from "../containers/SavedContentWidget"
import TestsWidget from "../containers/TestsWidget"

const cvLibraryPreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/cv-library-preview.png`)
const mentorMatcherPreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/mentor-matcher-preview.png`)
const testsPreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/tests-preview.png`)
const advicePreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/ask-expert-preview.png`)
const contentForYouPreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/content-for-you-preview.png`)
const jobMatcherPreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-matcher-widget-preview.png`)
const jobFinderPreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/job-finder-free-no-bg.svg`)
const trainingCentrePreview = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/career-developer/widget-images/training-center-preview.png`)

// Provides components for each widget key and additional parameters that will be applied to grid item
// businessLibrary widget not currently available
// Widgets with the 3 diget ID our for the static widgets
const widgetDictonary = {
  jobMatcher: {
    Component: JobMatcherWidget,
    id: "5ff483e3b291426048668bd6",
    removable: true,
    parameters: { minW: mobileColumns, minH: 7, maxH: 12 },
    defaults: { h: 7 },
    image: jobMatcherPreview,
    name: "Job Matcher"
  },
  cvLibrary: {
    Component: CVLibraryWidget,
    id: "5ff483e3b291426048668bd1",
    removable: true,
    parameters: { minW: mobileColumns, minH: 8, maxH: 14 },
    defaults: { h: 8 },
    image: cvLibraryPreview,
    name: "CV Library"
  },
  businessLibrary: {
    id: "5ff483e3b291426048668bd5",
    removable: true,
    parameters: { minW: mobileColumns, minH: 8 },
    defaults: { h: 8 },
    image: null,
    name: "Business Library",
    disabled: true
  },
  mentorMatcher: {
    Component: MentorMatcherWidget,
    id: "5ff483e3b291426048668bd7",
    removable: true,
    parameters: { minW: mobileColumns, minH: 7, maxH: 12 },
    defaults: { h: 7 },
    image: mentorMatcherPreview,
    name: "Mentor Matcher"
  },
  profileTests: {
    Component: TestsWidget,
    id: "5ff483e3b291426048668bd2",
    removable: true,
    parameters: { minW: mobileColumns, minH: 7, maxH: 9 },
    defaults: { h: 9 },
    image: testsPreview,
    name: "Tests"
  },
  expertAdvice: {
    Component: AdviceWidget,
    id: "5ff483e3b291426048668bd4",
    removable: true,
    parameters: { minW: mobileColumns, minH: 7 },
    defaults: { h: 7 },
    image: advicePreview,
    name: "Ask An Expert"
  },
  jobFinder: {
    Component: JobFinderWidget,
    id: "600ad3d3d95fbbe074d4142f",
    removable: true,
    parameters: { minW: mobileColumns, minH: 7 },
    defaults: { h: 7 },
    image: jobFinderPreview,
    name: "Job Finder"
  },
  savedContent: {
    Component: SavedContentWidget,
    id: "002",
    parameters: { minW: mobileColumns, minH: 6, maxH: 27 },
    defaults: { h: 6 }
  },
  contentForYou: {
    Component: ContentForYouWidget,
    id: "5ff483e3b291426048668bd3",
    removable: true,
    parameters: { minW: mobileColumns, minH: 10, maxH: 30 },
    defaults: { h: 30 },
    image: contentForYouPreview,
    name: "Content For You",
    disabled: false
  },
  trainingCentre: {
    Component: TrainingCentreWidget,
    id: "5ff483e3b291026048668bd9",
    removable: true,
    parameters: { minW: mobileColumns, minH: 10, maxH: 30 },
    defaults: { h: 30 },
    image: trainingCentrePreview,
    name: "Training Centre",
    disabled: false
  },
  achievingGoals: {
    Component: GoalWidget,
    id: "003",
    parameters: { static: true }
  },
  latestScore: {
    Component: LatestScore,
    id: "004",
    parameters: { static: true }
  },
  manageAlerts: {
    Component: ManageAlertsWidget,
    id: "005",
    parameters: { static: true }
  },
  profile: { Component: ProfileWidget, id: "006", parameters: { static: true } }
}

export default widgetDictonary
