import React from "react"

const AwardBadgeSVG = ({
  bgColor = "#FFD700",
  logoBgColor = "#003E4C",
  width = "50px",
  height = "50px",
  year = "2023"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 206 300"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_175_1192" fill="white">
        <path d="M79 153.222L152.083 135L174.098 223.297L133.5 216.5L101.015 241.518L79 153.222Z" />
      </mask>
      <path
        d="M79 153.222L152.083 135L174.098 223.297L133.5 216.5L101.015 241.518L79 153.222Z"
        fill={bgColor}
        stroke="black"
        strokeWidth="4"
        mask="url(#path-1-inside-1_175_1192)"
      />
      <mask id="path-2-inside-2_175_1192" fill="white">
        <path d="M54.0156 144L127.098 162.222L105.084 250.518L74.0007 226L32.0007 232.297L54.0156 144Z" />
      </mask>
      <path
        d="M54.0156 144L127.098 162.222L105.084 250.518L74.0007 226L32.0007 232.297L54.0156 144Z"
        fill={bgColor}
        stroke="black"
        strokeWidth="4"
        mask="url(#path-2-inside-2_175_1192)"
      />
      <path
        d="M103 3L124.658 14.6233H149.814L163.441 35.4718L185.903 46.8303L188.121 71.4952L203 92.243L193.661 115.637L197.188 140.458L178.551 156.226L169.799 180.429L145.309 185.654L127.107 203L103.504 196.309L78.8927 203L60.6916 185.654L36.2007 180.429L28.4566 156.734L8.81164 140.458L12.8428 116.651L3 92.243L18.3832 71.4952L20.0972 46.8303L42.5594 35.9792L56.1864 14.6233L80.8385 15.6843L103 3Z"
        fill={bgColor}
        stroke="black"
        strokeWidth="4"
      />
      <circle cx="103" cy="103" r="76" stroke="black" strokeWidth="2" />
      <path
        d="M74.3372 143L49 69H65.5748L80.9883 117.946L96.7185 69H109.598L125.223 117.946L140.742 69H157L131.663 143H118.677L102.947 93.6314L87.217 143H74.3372Z"
        fill={logoBgColor}
        stroke={logoBgColor}
        strokeWidth="2"
      />
      <text fill="#003E4C" fontFamily="Centra No2" fontSize="60" fontWeight="bold">
        <tspan x="44" y="290">
          {year}
        </tspan>
      </text>
    </svg>
  )
}

export default AwardBadgeSVG
