import { Card, Flex, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"

import AutoCarousel from "../../../components/auto-carousal"
import { SINGLE_CARD_CAROUSEL_TYPE } from "../../../components/auto-carousal/autoCarousel.styled"

export const EmptyCarousalCard = () => {
  return (
    <Card p={3} width={[1 / 1, 1 / 1, "240px"]} bg="primary.5">
      <Flex flexDirection="row" width={1 / 1}>
        <Text fontSize={16} fontWeight={400} lineHeight="21px">
          Interested in becoming a partner?&nbsp;
          <Link to="https://workl.com/partners" newTab external>
            <Text fontSize={16} fontWeight={700} color="primary.0" lineHeight="21px">
              Click here
            </Text>
          </Link>
          &nbsp;to find out more
        </Text>
      </Flex>
    </Card>
  )
}

const WHWSponsorsCarousel = ({ label = "Global Partner", slides = [], options = {}, isLoading }) => {
  return (
    <Flex flexDirection="column" width={1 / 1}>
      <Text fontSize={18} fontWeight={700} mb={2} color="primary.0" width="auto" lineHeight="23px">
        {label}
      </Text>
      {isLoading || !slides.length ? (
        <EmptyCarousalCard />
      ) : (
        <AutoCarousel type={SINGLE_CARD_CAROUSEL_TYPE} slides={slides} options={{ ...options }} />
      )}
      <Flex justifyContent="flex-end">
        <Link to="https://workl.com/partners/" external newTab>
          <Text color="primary.0" fontWeight="500" fontSize={[4, 3]} lineHeight="14px">
            Find Out More
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}

export default WHWSponsorsCarousel
