import { gql } from "../../../lib/Apollo"

export const LOAD = gql`
  query getBusinessInformationProfile($key: String!, $awardYear: String) {
    getBusinessInformationProfile(companyNameKey: $key, awardYear: $awardYear) {
      company
      ranking
      happinessScores {
        industry
        score
        global
      }
      diversityAndInclusion {
        name
        scores {
          label
          values {
            score
            industry
            global
          }
        }
      }
      sixSteps {
        key
        name
        score
        breakdown {
          question
          score
        }
      }
      companyNameKey
      surveyCompanyId
      industry {
        id
        name
      }
      country {
        id
        name
      }
      recommendation
      profile {
        organisationId
        id
        active
        status
        summary {
          banner
          name
          size
          websiteURL
          logoUrl
          services
          contactEmail
          videos
          industry {
            id
            name
          }
          awardsLogo
          foundedAt
          locations {
            city
            country {
              id
              name
            }
          }
          social {
            linkedIn
            twitter
            youtube
            pinterest
            facebook
            instagram
          }
        }
        culture {
          statement
          shortStatement
        }
      }
      indicators {
        wellBeingRisk {
          industry
          global
          total
          count
          average {
            score
            global
            industry
          }
          questions {
            score
            global
            industry
            questionId
            question
          }
        }
        managementConfidence {
          questions {
            question
            questionId
            industry
            global
            score
          }
          average {
            score
            industry
            global
          }
        }
        flightRisk {
          industry
          total
          count
          global
          average {
            score
            global
            industry
          }
          questions {
            question
            questionId
            score
            industry
            global
          }
        }
        diIndex {
          section
          minority {
            score
            industry
            global
          }
          majority {
            score
            industry
            global
          }
          questions {
            questionId
            question
          }
        }
      }
    }
  }
`

export const GET_DUNNS_DATA_BY_KEY = gql`
  query ($companyNameKey: String!) {
    getCompany(companyNameKey: $companyNameKey) {
      companyName
      companyNameKey
      employeesInfo {
        totalEmployees
      }
      industries
      country
      businessInfo {
        address {
          street1
          street2
          stateProvince
          postalCode
          country
          city
        }
        businessLine
        establishmentYear
        name
      }
      headquarterInfo {
        address {
          city
          country
          postalCode
          stateProvince
          street1
          street2
        }
      }
    }
  }
`

export const GET_AWARDS_WINS = gql`
  query getAwardWins($companyNameKey: String) {
    getAwardWins(companyNameKey: $companyNameKey) {
      logoUrls
      awardWins
      happinessScore
      sundayTimesAward
      year
      __typename
    }
  }
`
