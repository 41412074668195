/* eslint-disable no-nested-ternary */
import { CircularProgress } from "@engaging-tech/charts"
import { Button, Flex, Text } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { businessProfilePageSelectors } from ".."
import useWorkLAppQuery from "../../../hooks/use-workl-app-query"
import { getLabelData } from "../../../lib/utils"
import { GET_AWARDS_WINS } from "../services/businessProfilePage.queries"

const scoreWidget = score => (
  <Flex width={1 / 1} justifyContent="center">
    <CircularProgress
      percentage={score}
      width={50}
      height={50}
      strokeColor={getLabelData(score).color}
      thickness={0.45}
      fontSizeNoLabel={120}
    />
  </Flex>
)

const AwardsTabWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`

const AwardsTabContainer = styled.div`
  width: auto;

  margin: 20px 10px;

  @media (min-width: 768px) {
    margin: 42px 64px;
  }

  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const AwardsTabTitle = styled(Text)`
  margin-top: 11px;

  width: fit-content;

  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;

  color: #003e4c;
`

const AwardsTabTableContainer = styled(Flex)`
  padding-top: 20px;

  width: 100%;

  flex-direction: column;
  justify-items: left;
`

const AwardsTabTableTitle = styled(Text)`
  padding: 10px;

  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;

  color: #003e4c;

  @media (min-width: 768px) {
    padding: 0 0 0 32px;
  }
`

const AwardsTabAwardsListContainer = styled(Flex)`
  margin-bottom: 20px;

  overflow-x: auto;
  padding: 20px 46px 46px;

  gap: 30px;
`

const AwardsTable = styled.table`
  display: none;

  @media (min-width: 768px) {
    display: table;
  }

  margin-top: 12px;

  width: 100%;

  border-collapse: collapse;
  table-layout: fixed;
`

const AwardsTableHeaderRow = styled.tr`
  background-color: #fafafa;

  box-shadow: 0px 0px 2px 0px #00000026;
`

const AwardsTableHead = styled.th`
  height: 72px;
`

const AwardsTableBody = styled.tbody`
  box-shadow: 0px 0px 2px 0px #00000026;
`

const AwardsTableBodyRow = styled.tr`
  height: 100px;
`

const AwardsTableBodyRowItem = styled.td`
  text-align: center;
`

const AwardsTableBodyRowItemText = styled.td`
  height: 100%;
  min-height: 72px;
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const AwardsList = styled.div`
  margin-top: 12px;

  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: #f5f5f5;

  @media (min-width: 768px) {
    display: none;
  }
`

const AwardsListItem = styled.div`
  padding: 6px 4px;

  width: 100%;

  display: flex;
  justify-content: space-around;
`

const AwardsListItemContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`

const AwardsListItemTextContainer = styled(AwardsListItemContainer)`
  flex-direction: column;
`

const NoAwardsWinsWrapper = styled.div`
  width: 100%;

  margin-bottom: 48px;
  padding: 0 10px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    margin-bottom: 36px;

    flex-direction: column;
  }
`

const NoAwardsWinsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ClaimPageWrapper = styled.div`
  padding: 0 10px 0;
  margin-bottom: 36px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    margin-bottom: 65px;

    flex-direction: column;
  }
`

const ClaimPageText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  color: #003e4c;

  @media (min-width: 768px) {
    margin-left: 42px;
  }
`

const SundayTimesAwardsContainer = styled.div`
  padding: 0 0 0 10px;

  @media (min-width: 768px) {
    padding: 0 0 0 32px;
  }
`
const AwardLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const AwardLogoText = styled(Text)`
  display: flex;
  justify-content: center;
  color: #003e4c;
  font-weight: 600;
`

const AwardBadgeRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  alignitems: center;
`

const AwardBadgeColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const AwardWinsBadges = ({ awardWins, award }) => {
  if (!awardWins?.length) return null

  return (
    <AwardBadgeRowWrapper>
      {awardWins?.map(logo => (
        <AwardBadgeColumn>
          <img
            key={logo}
            alt="WorkL Award Best Places to Work"
            src={logo}
            loading="lazy"
            width="44px"
            height="54px"
          />
          <AwardLogoText>{award?.year}</AwardLogoText>
        </AwardBadgeColumn>
      ))}
    </AwardBadgeRowWrapper>
  )
}

export default function AwardsTab({ currAwardYear }) {
  const { profileId } = useParams()
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)

  const { data: awardsData, getData } = useWorkLAppQuery(GET_AWARDS_WINS, { enabledOnMount: false })

  useEffect(() => {
    getData({ companyNameKey: profileId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, currAwardYear])

  return (
    <AwardsTabWrapper>
      <AwardsTabContainer>
        <AwardsTabTitle>Awards Won</AwardsTabTitle>

        <AwardsTabTableContainer>
          <AwardsTabTableTitle>Awards powered by WorkL</AwardsTabTableTitle>

          <Flex width="100%" justifyContent="center">
            {awardsData?.getAwardWins?.length ? (
              <>
                <AwardsTable>
                  <thead>
                    <AwardsTableHeaderRow>
                      <AwardsTableHead>Year</AwardsTableHead>
                      <AwardsTableHead>Award Wins</AwardsTableHead>
                      <AwardsTableHead>Happiness Score</AwardsTableHead>
                    </AwardsTableHeaderRow>
                  </thead>
                  <AwardsTableBody>
                    {awardsData.getAwardWins.map((award, index) => {
                      if (award?.awardWins) {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <AwardsTableBodyRow key={index}>
                            <AwardsTableBodyRowItem>
                              <AwardWinsBadges awardWins={award.logoUrls} award={award} />
                            </AwardsTableBodyRowItem>
                            <AwardsTableBodyRowItemText>
                              {award.awardWins?.map(win => (
                                <Text key={win} textAlign="center">
                                  {win}
                                </Text>
                              ))}
                            </AwardsTableBodyRowItemText>
                            <AwardsTableBodyRowItem>
                              {award?.happinessScore ? scoreWidget(award.happinessScore) : null}
                            </AwardsTableBodyRowItem>
                          </AwardsTableBodyRow>
                        )
                      }
                      return null
                    })}
                  </AwardsTableBody>
                </AwardsTable>
                <AwardsList>
                  {awardsData?.getAwardWins?.map((award, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <AwardsListItem key={index}>
                      <AwardsListItemContainer>
                        <AwardLogoWrapper>
                          <AwardWinsBadges awardWins={award.logoUrls} award={award} />
                        </AwardLogoWrapper>
                      </AwardsListItemContainer>
                      <AwardsListItemTextContainer>
                        {award.awardWins?.map(win => (
                          <Text key={win} textAlign="center">
                            {win}
                          </Text>
                        ))}
                      </AwardsListItemTextContainer>
                      <AwardsListItemContainer>
                        {award?.happinessScore ? scoreWidget(award.happinessScore) : null}
                      </AwardsListItemContainer>
                    </AwardsListItem>
                  ))}
                </AwardsList>
              </>
            ) : (
              <>
                <NoAwardsWinsWrapper>
                  <NoAwardsWinsContainer>
                    <Text fontSize="18px" fontWeight="700" lineHeight="23.4px" color="#003E4C">
                      No Award Wins yet?
                    </Text>
                    <Text mt="4px" fontSize="16px" lineHeight="20.8px" color="#003E4C">
                      Find out how to win
                    </Text>
                  </NoAwardsWinsContainer>
                  <Button
                    mt="12px"
                    width="fit-content"
                    minWidth="105px"
                    variant="primary.0"
                    onClick={e => {
                      e.preventDefault()

                      window.open(
                        `${getConfig().worklForBusiness.public.index}${
                          getConfig().worklForBusiness.public.paths.worklBusinessWorkplaceAwards
                        }`,
                        "__blank"
                      )
                    }}
                  >
                    BE A WINNER
                  </Button>
                </NoAwardsWinsWrapper>
              </>
            )}
          </Flex>

          <AwardsTabTableTitle mt="17px">Other Awards</AwardsTabTableTitle>
          <SundayTimesAwardsContainer>
            {awardsData?.getAwardWins?.map(award => {
              if (award?.sundayTimesAward) {
                return (
                  <img
                    key={award.sundayTimesAward}
                    alt="Sunday Times Award"
                    src={award.sundayTimesAward}
                    style={{ marginTop: "24px" }}
                    loading="lazy"
                    width="152px"
                    height="54px"
                  />
                )
              }
              return null
            })}
          </SundayTimesAwardsContainer>
          {bipInfo?.profile?.status !== "PUBLISHED" ? (
            <ClaimPageWrapper>
              <ClaimPageText>Claim this page to add more awards that you have won.</ClaimPageText>
              <Button
                variant="primary.0"
                mt={["0", "24px"]}
                width="fit-content"
                minWidth="105px"
                onClick={e => {
                  e.preventDefault()

                  window.open(
                    `${getConfig().worklForBusiness.public.index}${
                      getConfig().worklForBusiness.public.paths.worklBusinessServices
                    }`,
                    "__blank"
                  )
                }}
              >
                CLAIM PAGE
              </Button>
            </ClaimPageWrapper>
          ) : bipInfo?.profile?.summary?.awardsLogo?.length ? (
            <AwardsTabAwardsListContainer>
              {bipInfo.profile.summary.awardsLogo.map((link, index) => {
                if (link === null) return null

                return (
                  <img
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    alt="WorkL Award Best Places to Work"
                    src={`${getConfig().awsImageEndpoint}${link}`}
                    loading="lazy"
                    width="fit-content"
                    style={{ maxWidth: "240px" }}
                  />
                )
              })}
            </AwardsTabAwardsListContainer>
          ) : (
            <div style={{ marginBottom: "48px" }} />
          )}
        </AwardsTabTableContainer>
      </AwardsTabContainer>
    </AwardsTabWrapper>
  )
}
