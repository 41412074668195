import { Flex } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"

import { businessProfilePageSelectors } from "../../features/business-profile"
import BusinessInformation from "../../features/business-profile/views/business-profile-view"
import AppViewBaseLayout from "../../layouts/AppViewBaseLayout"

export default function BusinessProfile() {
  const bipInfo = useSelector(businessProfilePageSelectors.getBipInfo)

  return (
    <AppViewBaseLayout bg={bipInfo?.profile?.status === "PUBLISHED" ? "#006666" : "#F5F5F5"}>
      <Flex justifyContent="center">
        <BusinessInformation />
      </Flex>
    </AppViewBaseLayout>
  )
}
