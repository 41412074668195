export const types = {
  LOAD_COMPANY_RANKINGS: "[Rankings] Company Load",
  LOAD_COMPANY_SUCCESS: "[Rankings] Company Load Success",
  LOAD_FAIL: "[Rankings] Load Fail",
  LOAD_MORE: "[Rankings] Load More",
  LOAD_MORE_SUCCESS: "[Rankings] Load More Success",
  LOAD_MORE_FAIL: "[Rankings] Load More Fail",
  CLEAR_FILTERS: "[Rankings] Clear Filters",
  CLEAR_SEARCH: "[Rankings] Clear Search",
  GET_SURVEY_COMPANIES: "[Rankings] Get Survey Companies",
  GET_SURVEY_COMPANIES_SUCCESS: "[Rankings] Get Survey Companies Success",
  GET_SURVEY_COMPANIES_FAIL: "[Rankings] Get Survey Companies Fail",

  LOAD_COMPANIES: "[Companies] Companies Load",
  LOAD_COMPANIES_SUCCESS: "[Companies] Companies Load Success",
  LOAD_COMPANIES_FAIL: "[Companies] Companies Load Fail",
  LOAD_COMPANIES_LOAD_MORE: "[Companies] Companies Load More",
  LOAD_COMPANIES_LOAD_MORE_SUCCESS: "[Companies] Companies Load More Success",
  LOAD_COMPANIES_LOAD_MORE_FAIL: "[Companies] Companies Load More Fail",
  APPLY_FILTERS: "[Rankings] Apply filters"
}

export const loadCompanies = payload => ({
  type: types.LOAD_COMPANIES,
  payload
})

export const loadCompaniesSuccess = companies => ({
  type: types.LOAD_COMPANIES_SUCCESS,
  payload: companies
})

export const loadCompaniesFail = () => ({
  type: types.LOAD_COMPANIES_FAIL
})

export const loadMoreCompanies = payload => ({
  type: types.LOAD_COMPANIES_LOAD_MORE,
  payload
})

export const loadMoreCompaniesSuccess = companies => ({
  type: types.LOAD_COMPANIES_LOAD_MORE_SUCCESS,
  payload: companies
})

export const loadMoreCompaniesFail = () => ({
  type: types.LOAD_COMPANIES_LOAD_MORE_FAIL
})

export const loadCompanyRankings = (from, term, industryId, countryId, type) => {
  return {
    type: types.LOAD_COMPANY_RANKINGS,
    payload: {
      from,
      term,
      industryId: industryId === "0" ? null : industryId,
      countryId: countryId === "0" ? null : countryId,
      type
    }
  }
}

export const loadCompanySuccess = payload => ({
  type: types.LOAD_COMPANY_SUCCESS,
  payload
})

export const loadFail = () => ({
  type: types.LOAD_FAIL
})

export const loadMore = (from, term, industryId, countryId, type) => ({
  type: types.LOAD_MORE,
  payload: {
    from,
    term,
    industryId: industryId === "0" ? null : industryId,
    countryId: countryId === "0" ? null : countryId,
    type
  }
})

export const loadMoreSuccess = companies => ({
  type: types.LOAD_MORE_SUCCESS,
  payload: companies
})

export const loadMoreFail = () => ({
  type: types.LOAD_MORE_FAIL
})

export const getSurveyCompanies = searchTerm => ({
  type: types.GET_SURVEY_COMPANIES,
  payload: { searchTerm }
})

export const getSurveyCompaniesSuccess = companies => ({
  type: types.GET_SURVEY_COMPANIES_SUCCESS,
  payload: companies
})

export const getSurveyCompaniesFail = () => ({
  type: types.GET_SURVEY_COMPANIES_FAIL
})

export const applyFilters = filters => ({
  type: types.APPLY_FILTERS,
  payload: filters
})
