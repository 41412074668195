import { gql } from "@apollo/client"

export const GET_WHW_SPONSORS_BY_COUNTRY = gql`
  query ($countryId: String) {
    getAwardSponsors(countryId: $countryId) {
      globalPartners {
        data {
          description
          imageUrl
          redirectionUrl
          sponsorCategory
        }
        label
      }

      mediaSponsors {
        data {
          description
          imageUrl
          redirectionUrl
          sponsorCategory
        }
        label
      }

      partners {
        data {
          description
          imageUrl
          redirectionUrl
          sponsorCategory
        }
        label
      }

      sponsors {
        data {
          description
          imageUrl
          redirectionUrl
          sponsorCategory
        }
        label
      }
    }
  }
`
