import { gql } from "@apollo/client"

export const GET_WHW_FILTERS = gql`
  query {
    getFiltersMeta {
      awards {
        id
        name
      }
      country {
        id
        name
      }
      industry {
        id
        name
      }
    }
  }
`
