import { createSelector } from "reselect"

const accountState = state => state && state.account

export const getIsLoading = createSelector(accountState, state => state.isLoading)

export const getIsAuthenticated = createSelector(
  state => state,
  state => {
    if (state.account?.isAuthenticated && state.yourProfile?.profile?.id) return true
    return false
  }
)

export const getUserId = createSelector(
  state => state,
  state => state.yourProfile?.profile?.id
)

export const getToken = createSelector(accountState, state => state?.token || null)

export const getAccountEmail = createSelector(getToken, token => token?.email)

export const getIsNotificationVisible = createSelector(accountState, state => !!state.error)

export const getTokenUserId = createSelector(getToken, token => token?.sub)

export const isMagicLinkSent = createSelector(accountState, state => state?.magicLinkSent)
