import styled from "styled-components"

export const SINGLE_CARD_CAROUSEL_TYPE = "SINGLE_CARD"
export const MULTI_CARD_CAROUSEL_TYPE = "MULTI_CARD"

export const EmblaFlex = styled.div`
  --slide-height: 80px;
  --slide-spacing: 1rem;
  --slide-size: ${({ type }) => (type === SINGLE_CARD_CAROUSEL_TYPE ? "100%" : "20%")};
  --slide-width: 80px;
  width: 100%;
`
export const EmblaViewport = styled.div`
  overflow: hidden;
`
export const EmblaContainer = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * 1);
  justify-content: ${({ totalSlides, type }) =>
    totalSlides > 2 || type === SINGLE_CARD_CAROUSEL_TYPE ? "flex-start" : "center"};
`
export const EmblaSlide = styled.div`
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
`
export const EmblaSlideCard = styled.div`
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  border: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  margin: 5px;
  padding: 5px;
  height: var(--slide-height);
`

export const EmblaSlideImgContainer = styled.div`
  height: ${({ type }) => (type === SINGLE_CARD_CAROUSEL_TYPE ? "150px" : "80px")};
  width: ${({ type }) => (type === SINGLE_CARD_CAROUSEL_TYPE ? "150px" : "80px")};
  align-items: center;
  display: flex;
  justify-content: center;
`

export const EmblaSlideImg = styled.img`
  width: 100%; /* This if for the object-fit */
  object-fit: contain; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
`
