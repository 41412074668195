import { Navbar } from "@engaging-tech/navigation"
import { usePaths, useRouter, useRoutes } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { ThirdPartyActions } from "@engaging-tech/third-party-cookies"
import { Auth } from "aws-amplify"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { getIsAuthenticated, getUserId } from "../../account/store/account.selectors"
import { BookmarkActions } from "../../bookmarks"
import { MyLibraryActions } from "../../myLibrary"
import { yourProfileActions } from "../../yourProfile"
import { getIsPremium, getProfile } from "../../yourProfile/store/yourProfile.selectors"
import SiteLogo from "./SiteLogo"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Nav = () => {
  const routes = useRoutes()
  const paths = usePaths()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isPremium = useSelector(getIsPremium)
  const userProfile = useSelector(getProfile)
  const userId = useSelector(getUserId)

  const dispatch = useDispatch()

  const onSignOut = async () => {
    dispatch(ThirdPartyActions.setClearConsent())
    dispatch(yourProfileActions.reset())
    dispatch(MyLibraryActions.reset())
    dispatch(BookmarkActions.reset())
    await Auth.signOut()
  }

  const navRoutes = routes.filter(
    route =>
      route.path === paths?.findAJob?.index ||
      route.path === paths?.careerDeveloper?.index ||
      route.path === paths?.globalHub?.index ||
      route.path === paths?.resourceCentre?.index ||
      route.path === paths?.worldsHappiestWorkplaces?.index ||
      route.path === paths?.messenger
  )

  const allAccountRoutes = routes.filter(route => route.path.includes(paths.account.index))[0].children

  const userAccountRoutes = allAccountRoutes

  const auxillaryRoutes = [
    {
      linkProps: {
        external: true,
        newTab: true
      },
      description: "For Business",
      path: getConfig().worklForBusiness.public.index
    }
  ]

  const premiumRoute = routes.find(route => route.path === paths.getPremium.index)

  const signedOutRoutes = userAccountRoutes.filter(
    route => route.path === paths.account.signIn || route.path === paths.account.signUp
  )

  const signedInRoutes = [
    {
      linkProps: {
        onClick: onSignOut
      },
      description: "Sign Out",
      icon: "meeting_room"
    }
  ]

  const offAxisButtonRoute = routes.find(route => route.path === paths.happinessSurvey.index)

  return (
    <Navbar
      siteLogo={SiteLogo}
      routes={navRoutes}
      accountRoutes={isAuthenticated ? signedInRoutes : signedOutRoutes}
      auxillaryRoutes={auxillaryRoutes}
      navbarItemColor={{ selected: "primary.0", unSelected: "primary.1" }}
      tabBarColor="tertiary.0"
      userDetails={{
        isAuthenticated,
        details: userProfile,
        premiumColor: "dark.6",
        nonPremiumColor: "dark.6",
        onClick() {
          window.location.href = `/profile/${userId}`
        }
      }}
      offAxisButtonRoute={{
        ...offAxisButtonRoute,
        description: "HAPPY AT WORK TEST"
      }}
      accountRoutesProps={{
        bg: "tertiary.0"
      }}
      premiumRoute={isPremium ? null : premiumRoute}
      elevation={3}
      height={120}
      holdingImage={profilePlaceholder}
      profilePath={`/profile/${userId}`}
    />
  )
}

export default Nav
