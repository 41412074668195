import { Flex, List, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import React from "react"
import styled from "styled-components"

const AwardRankingBannerWrapper = styled.div`
  width: 100%;
  background-color: #e6f7ef;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TelegraphText = () => (
  <Flex flexDirection="column">
    <DesktopTitles />
    <List.Wrapper spacing="8px">
      <List.Item>
        <Text fontSize={[3, 4]} color="dark.1">
          Rankings are based on the data collected through our free
          <Link to="/happiness-survey">
            <Text fontWeight="bold" ml={1} fontSize={[3, 4]} color="primary.0">
              Happy at Work Test.
            </Text>
          </Link>
        </Text>
      </List.Item>
      <List.Item fontSize={[3, 4]} color="dark.1">
        Add and apply filters to find the happiest workplaces for you.
      </List.Item>
      <List.Item fontSize={[3, 4]} color="dark.1">
      <Text>
        Take our 
        <Link to="/happiness-survey">
          <Text fontWeight="bold" ml={1} pr={1} fontSize={[3, 4]} color="primary.0">
            Happy at Work Test
          </Text>
        </Link>
        to enter your workplace into the World's Happiest Workplaces Awards.</Text>
      </List.Item>
      <List.Item fontSize={[3, 4]} color="dark.1">
        <Text>
          Find out more about our awards, sponsors and partners 
          <Link to="https://workl.com/partners/" newTab>
            <Text fontWeight="bold" ml={1} pr={1} fontSize={[3, 4]} color="primary.0">
            here.
            </Text>
          </Link>
        </Text>
      </List.Item>
    </List.Wrapper>
  </Flex>
)

const titleText = [
  "World’s Happiest Workplaces List",
  "The World’s Happiest Workplaces List is a live ranking with insights into how happy employees are where they work."
]

const MobileTitles = () => (
  <Flex flexDirection="column" display={["block", "block", "none"]} mb={2} alignItems="center">
    <Text
      fontSize={[5, 7]}
      fontWeight={600}
      textAlign="center"
      color="primary.0"
      width="auto"
      fontFamily="Tiempos"
      mb={[0, 4, 4]}
    >
      {titleText[0]}
    </Text>
    <br />
    <Text fontSize={4} fontWeight={500} textAlign="center" color="dark.1" width="auto">
      {titleText[1]}
    </Text>
  </Flex>
)

const DesktopTitles = () => (
  <>
    <Text
      display={["none", "none", "block"]}
      fontSize={[5, 7]}
      fontWeight={600}
      textAlign="center"
      color="primary.0"
      width="auto"
      fontFamily="Tiempos"
      mb={[0, 4, 4]}
    >
      {titleText[0]}
    </Text>
    <Text
      display={["none", "none", "block"]}
      fontSize={4}
      fontWeight={500}
      textAlign="left"
      color="dark.1"
      width="auto"
    >
      {titleText[1]}
    </Text>
  </>
)

const AwardRankingBanner = ({ ...props }) => {
  return (
    <AwardRankingBannerWrapper>
      <Flex
        justifyContent="center"
        flexDirection="column"
        width={1 / 1}
        style={{ padding: "30px", maxWidth: "1280px" }}
      >
        <MobileTitles />
        <Flex flexDirection={["column", "row", "row"]} alignItems={["center", "center"]} {...props}>
          <TelegraphText />
        </Flex>
      </Flex>
    </AwardRankingBannerWrapper>
  )
}

export default AwardRankingBanner
