import React from "react"

const FilterIconSVG = ({ width = "18px", height = "18px", color = "#FFFFF" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.22223H9" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M10 11L21 11" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M1 18.7778H12.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M1 11H6.33333" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M21 3.22223L13 3.22223" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M21 18.7778L15.6667 18.7778" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M6.33325 8.77777L6.33325 13.2222" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12.9995 5.44446L12.9995 1.00001" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M15.6667 21L15.6667 16.5556" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export default FilterIconSVG
